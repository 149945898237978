import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
// import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({
  heading,
  headingLevel,
  ctaLink,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container my-[104px] mx-auto max-w-[1267px]">
      <section
        className={`rounded-4xl bg-brand-linear-gradient px-8 py-20 text-center md:px-16 md:py-20 md:text-left ${
          className || ""
        }`}
      >
        <div className="flex flex-col items-center justify-center gap-y-6">
          <div className="mb-6 max-w-[720px] md:mb-0">
            <HeadingTag className="mb-4 bg-gradient-to-r from-primary-700 via-primary-400 to-primary-400 bg-clip-text text-transparent">
              {heading || ["Get the Secret to Real Estate Success"]}
            </HeadingTag>
            <p className="mb-0 text-center text-button-primary">
              It takes less than 60 seconds to get started. Cancel anytime.
            </p>
          </div>
          <div className="flex !w-full !max-w-[311px] flex-col gap-y-6 gap-x-3 lg:flex-row">
            <ButtonSolid
              altStyle={2}
              // modal="modal-contact"
              text="Start Free Trial"
              href="/free-trial"
              className="!w-full !max-w-[311px] lg:w-fit"
            />
            <ButtonSolid
              altStyle={3}
              // modal="modal-contact"
              href="/free-trial"
              text="Request Demo"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CTA;
